import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import JustText from '../components/common/sections/justText';

const CovidResponsePage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <JustText contentSection={contentSections[0]} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "3610caf9-794f-56c5-a3b9-81884863df26" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export default CovidResponsePage;
